<template>
  <div>
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">{{ title }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <v-card :loading="loading" class="mb-4 pa-6">
        <v-row>
          <v-col cols="6">
            <v-select
              menu-props="auto"
              v-model="selectedCarrier"
              :items="carriersWithServices"
              item-text="name"
              return-object
              label="Select Carrier"
              outlined
              dense
              @change="setCarrierServices"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              v-if="selectedCarrier && selectedCarrier.id && carrierServices"
              menu-props="auto"
              v-model="selectedCarrierService"
              :items="carrierServices"
              item-text="service"
              return-object
              label="Select Carrier Service"
              outlined
              dense
              @change="getServiceData"
            ></v-select>
          </v-col>
        </v-row>
        <v-row
          v-if="
            selectedCarrier &&
            selectedCarrier.id &&
            selectedCarrierService &&
            selectedCarrierService.id
          "
        >
          <v-divider />
          <v-col cols="12"
            ><b> {{ selectedCarrierService.service }}</b>
          </v-col>
          <!-- service settings -->
          <v-col cols="12">
            <v-card outlined class="pa-2" :loading="loading">
               <v-card-title>Service Settings</v-card-title>
              <v-row class="pa-4">
                <v-col cols="4">
                  <v-select
                  hide-details
                    menu-props="auto"
                    v-model="costModel"
                    :items="costModelList"
                    item-text="description"
                    item-value="value"
                    label="Select Cost Model"
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-checkbox
                    class="mt-0"
                    v-model="usesVolWeight"
                    label="Uses Volumetric Weight"
                  ></v-checkbox>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-if="usesVolWeight"
                    outlined
                    dense
                    v-model="volWeightFactor"
                    type="number"
                    step="1"
                    label="Volumetric Weight Factor"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" align="center">
                    <v-btn
                    :disabled="!this.selectedCarrierService ||!costModel"
                    color="primary"
                    outlined
                    @click="saveServiceSettings"
                    >{{updating ? 'Update' :'Save'}} Service Settings
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <!-- insert new tag -->
          <v-col cols="6" >
            <v-card outlined class="pa-2" :loading="loading" style="height: 100%;">
              <v-row class="ma-2">
                <v-col cols="12">
                  For service to be available, it must match all tags (if any) included on the order, or alternatively add tag ALL_ORDER_TAGS to service to allow the service to be available no matter the order tags
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    outlined
                    v-model="tag"
                    label="tag"
                  ></v-text-field>
                </v-col>

                <v-col cols="4" align="center">
                  <v-btn
                    :disabled="!tag || !selectedCarrierService.id"
                    color="primary"
                    outlined
                    @click="insertServiceTag"
                    >Add Tag
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <!-- current tags -->
          <v-col cols="6">
            <v-card outlined class="pa-2">
              <v-card-title>Current Tags</v-card-title>
              <v-data-table
                :headers="tagHeaders"
                :items="currentServiceTags"
                :items-per-page="5"
                v-if="currentServiceTags.length"
              >
                <template v-slot:item.action="{ item }">
                  <v-btn
                    small
                    @click="deleteServiceTag(item)"
                    color="red"
                    outlined
                  >
                    <v-icon> delete </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
              <div v-else class="ma-4">This service has no tags yet</div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar" :timeout="3000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import Carriers from "@/services/Carriers.js";
export default {
  data() {
    return {
      validForm: true,
      // Title
      title: "Service Settings & Tags",
      // Snackbar
      snackbar: false,
      text: "",
      loading: false,

      carriersWithServices: [],
      selectedCarrier: null,
      carrierServices: null,
      selectedCarrierService: null,

      currentServiceTags: [],
      tagHeaders: [
        { text: "tag", value: "tag" },

        { text: "", value: "action" },
      ],
      tag: "",

      // updating form
      updating: false,
      usesVolWeight: null,
      volWeightFactor: null,
      costModel: null,
      costModelList: [
        { description: "per consignment", value: "per_consignment" },
        { description: "per package", value: "per_package" },
      ],
    };
  },

  created() {
    this.clearFields();
    this.getCarriersWithServices();
  },
  methods: {
    getServiceData() {
      this.getServiceSettings();
      this.getServiceTags();
    },
   
    setCarrierServices(carrier) {
      this.carrierServices = carrier.carrierServices;
    },

    getCarriersWithServices() {
      this.loading = true;
      Carriers.getCarriersWithServices()
        .then((response) => {
          this.loading = false;
          this.snackbar = true;
          this.text = `${response.message}`;
          this.carriersWithServices = response.data;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
    getServiceSettings() {
      this.loading = true;
      this.serviceSettings = null;
      this.clearFields();
      Carriers.getCarrierServiceSettings(this.selectedCarrierService.id)
        .then((response) => {
          this.loading = false;
          let currentSettings = response.data;

          if (currentSettings) {
            this.updating = true;
            this.usesVolWeight = currentSettings.uses_vol_weight;
            this.volWeightFactor = currentSettings.vol_weight_factor;
            this.costModel = currentSettings.cost_model;
          }
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
    saveServiceSettings() {
      let carrierServiceId = this.selectedCarrierService
        ? this.selectedCarrierService.id
        : null;
      let costModel = this.costModel;
      if (!carrierServiceId || !costModel) return false;

      let reqBody = {
        carrier_service_id: carrierServiceId,
        cost_model: costModel,
        uses_vol_weight: this.usesVolWeight,
        vol_weight_factor: this.volWeightFactor,
      };
      this.loading = true;

      Carriers.saveCarrierServiceSettings(reqBody)
        .then((response) => {
          this.loading = false;
          this.snackbar = true;
          this.text = `${response.message}`;
          this.clearFields();
          this.getServiceSettings();
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
    getServiceTags() {
      this.loading = true;
      this.currentServiceTags = [];

      Carriers.getCarrierServiceTags(this.selectedCarrierService.id)
        .then((response) => {
          this.loading = false;
          this.currentServiceTags = response.data;
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
    deleteServiceTag(item) {
      let confirmMsg = `Are you sure you want to delete tag ${item.tag} for ${item.carrier_service_id}`;
      if (confirm(confirmMsg)) {
        this.loading = true;
        let reqBody = {
          carrier_service_id: item.carrier_service_id,
          tag: item.tag,
        };
        Carriers.deleteCarrierServiceTag(reqBody)
          .then((response) => {
            this.loading = false;
            this.snackbar = true;
            this.text = `${response.message}`;

            this.getServiceTags();
          })
          .catch((error) => {
            this.snackbar = true;
            this.text = error.response
              ? error.response.data.message
              : `${error.message}`;
            this.loading = false;
          });
      }
    },
    insertServiceTag() {
      let carrierServiceId = this.selectedCarrierService
        ? this.selectedCarrierService.id
        : null;
      let tag = this.tag;
      if (!carrierServiceId || !tag) return false;

      let reqBody = {
        carrier_service_id: carrierServiceId,
        tag: tag,
      };
      this.loading = true;

      Carriers.insertCarrierServiceTag(reqBody)
        .then((response) => {
          this.loading = false;
          this.snackbar = true;
          this.text = `${response.message}`;
          this.clearFields();
          this.getServiceTags();
        })
        .catch((error) => {
          this.snackbar = true;
          this.text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.loading = false;
        });
    },
    clearFields() {
      this.tag = ''
      this.costModel = null 
      this.usesVolWeight = false 
      this.volWeightFactor = null 
      this.updating = false

    },
  },
};
</script>
<style></style>
